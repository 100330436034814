// src/components/AdminPasswordModal/AdminPasswordModal.js

import React, { useState, useContext } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    Backdrop,
    CircularProgress,
    Typography,
} from '@mui/material';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { AdminContext } from '../../contexts/AdminContext';

function AdminCodeModal({ open, onClose }) {
    const { setIsAdminAuthenticated } = useContext(AdminContext);
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleAuthenticate = async () => {
        setLoading(true);
        setError('');
        try {
            const configRef = doc(db, 'config', 'admin');
            const configSnap = await getDoc(configRef);

            if (configSnap.exists()) {
                const storedPassword = configSnap.data().password;
                if (password === storedPassword) {
                    setIsAdminAuthenticated(true);
                    onClose();
                } else {
                    setError('Mot de passe incorrect, veuillez réessayer.');
                }
            } else {
                setError('Configuration admin introuvable.');
            }
        } catch (err) {
            console.error('Erreur lors de l\'authentification admin :', err);
            setError('Une erreur est survenue lors de l\'authentification.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog
            open={open}
            onClose={() => {}}
            disableEscapeKeyDown
            aria-labelledby="admin-password-dialog-title"
            BackdropComponent={Backdrop}
            BackdropProps={{
                style: { backdropFilter: 'blur(5px)' },
            }}
        >
            <DialogTitle id="admin-password-dialog-title">Entrez le mot de passe administrateur</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Mot de passe"
                    type="password"
                    fullWidth
                    variant="outlined"
                    value={password}
                    onChange={handlePasswordChange}
                    error={Boolean(error)}
                    helperText={error}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleAuthenticate} variant="contained" color="primary" disabled={loading}>
                    {loading ? <CircularProgress size={24} /> : 'Valider'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default AdminCodeModal;
