// src/components/Enigme/Enigme.js

import React, { useState, useEffect, useContext } from 'react';
import {
    Box,
    Button,
    Typography,
    Alert,
    Snackbar,
    TextField,
    CircularProgress,
    IconButton,
    Paper,
} from '@mui/material';
import { db } from '../../firebase';
import { doc, getDoc, updateDoc, increment } from 'firebase/firestore';
import { TeamContext } from '../../contexts/TeamContext';
import { enigmeAnswers, enigmeLevels } from '../../config/config';
import { stringSimilarity } from 'string-similarity-js';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

function Enigme({ level }) {
    const { group, team } = useContext(TeamContext);
    const [userAnswers, setUserAnswers] = useState({});
    const [correctAnswers, setCorrectAnswers] = useState({});
    const [attempts, setAttempts] = useState(0);
    const [enigmeStatus, setEnigmeStatus] = useState('en_cours'); // 'en_cours', 'echoue', 'resolu'
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // 'success', 'warning', 'error'
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const [enigmeIds, setEnigmeIds] = useState([]);

    // Récupérer les données des énigmes pour le niveau actuel
    useEffect(() => {
        const fetchEnigmeData = async () => {
            try {
                // Obtenir les IDs des énigmes/puzzles pour le niveau actuel
                const ids = enigmeLevels[level];
                setEnigmeIds(ids);

                // Initialiser les réponses utilisateur et les indicateurs de réponses correctes
                const initialUserAnswers = {};
                const initialCorrectAnswers = {};
                ids.forEach((id) => {
                    initialUserAnswers[id] = '';
                    initialCorrectAnswers[id] = null; // null, true, or false
                });
                setUserAnswers(initialUserAnswers);
                setCorrectAnswers(initialCorrectAnswers);

                setLoading(false);
            } catch (error) {
                console.error('Erreur lors de la récupération des données des énigmes :', error);
                setLoading(false);
            }
        };

        fetchEnigmeData();
    }, [level]);

    // Récupérer les tentatives du groupe pour le niveau spécifique lors du montage du composant
    useEffect(() => {
        const fetchGroupData = async () => {
            if (group) {
                try {
                    const configRef = doc(db, 'config', 'game');
                    const configSnap = await getDoc(configRef);

                    if (configSnap.exists()) {
                        const currentGameId = configSnap.data().current;
                        const groupRef = doc(db, 'games', currentGameId, 'groups', group.id);
                        const groupSnap = await getDoc(groupRef);

                        if (groupSnap.exists()) {
                            const groupData = groupSnap.data();

                            // Récupérer les tentatives et le statut pour le niveau spécifique
                            const attemptsFromDB = (groupData.attemptsEnigme && groupData.attemptsEnigme[level]) || 0;
                            setAttempts(attemptsFromDB);

                            const statusFromDB = (groupData.enigmeStatus && groupData.enigmeStatus[level]) || 'en_cours';
                            setEnigmeStatus(statusFromDB);

                            // Récupérer les réponses correctes déjà trouvées
                            const correctAnswersFromDB = (groupData.correctAnswersEnigme && groupData.correctAnswersEnigme[level]) || {};
                            setCorrectAnswers(correctAnswersFromDB);
                        }
                    }
                } catch (error) {
                    console.error('Erreur lors de la récupération des données du groupe :', error);
                }
            }
        };

        fetchGroupData();
    }, [group, level]);

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    // Fonction pour normaliser les réponses
    const normalizeAnswer = (str) => {
        return str
            .toLowerCase()
            .normalize('NFD') // Normalise les caractères accentués
            .replace(/[\u0300-\u036f]/g, '') // Supprime les diacritiques
            .replace(/[^a-z0-9 ]/g, '') // Supprime les caractères spéciaux, en gardant les espaces
            .trim(); // Supprime les espaces au début et à la fin
    };

    const handleInputChange = (id, value) => {
        setUserAnswers((prev) => ({
            ...prev,
            [id]: value,
        }));
    };

    const handleValidate = async (id) => {
        if (enigmeStatus !== 'en_cours' || correctAnswers[id] === true || attempts >= 3) return;

        const userAnswer = userAnswers[id] || '';
        const [correctAnswer, answerPoints] = enigmeAnswers[id];

        // Normaliser les réponses
        const normalizedUserAnswer = normalizeAnswer(userAnswer);
        const normalizedCorrectAnswer = normalizeAnswer(correctAnswer);

        // Calculer la similarité entre les réponses
        const similarity = stringSimilarity(normalizedUserAnswer, normalizedCorrectAnswer);

        let isCorrect = false;
        let newAttempts = attempts; // Initialiser avec le nombre actuel de tentatives

        if (similarity >= 0.8) {
            // Réponse correcte
            isCorrect = true;
            setCorrectAnswers((prev) => ({
                ...prev,
                [id]: true,
            }));

            // Mettre à jour la base de données pour la réponse correcte
            try {
                const configRef = doc(db, 'config', 'game');
                const configSnap = await getDoc(configRef);

                if (configSnap.exists()) {
                    const currentGameId = configSnap.data().current;
                    const groupRef = doc(db, 'games', currentGameId, 'groups', group.id);
                    const teamRef = doc(db, 'games', currentGameId, 'teams', group.teamId);

                    // Mettre à jour la réponse correcte
                    await updateDoc(groupRef, {
                        [`correctAnswersEnigme.${level}.${id}`]: true,
                    });

                    // Ajouter les points si la réponse est correcte
                    await updateDoc(teamRef, {
                        score: increment(answerPoints),
                    });
                }
            } catch (error) {
                console.error('Erreur lors de la mise à jour de la réponse correcte :', error);
            }
        } else {
            // Réponse incorrecte
            isCorrect = false;
            setCorrectAnswers((prev) => ({
                ...prev,
                [id]: false,
            }));

            // Incrémenter les tentatives
            newAttempts = attempts + 1;
            setAttempts(newAttempts);

            // Mettre à jour la base de données pour la tentative échouée
            try {
                const configRef = doc(db, 'config', 'game');
                const configSnap = await getDoc(configRef);

                if (configSnap.exists()) {
                    const currentGameId = configSnap.data().current;
                    const groupRef = doc(db, 'games', currentGameId, 'groups', group.id);

                    // Mettre à jour les tentatives
                    await updateDoc(groupRef, {
                        [`attemptsEnigme.${level}`]: newAttempts,
                    });

                    // Mettre à jour la réponse incorrecte
                    await updateDoc(groupRef, {
                        [`correctAnswersEnigme.${level}.${id}`]: false,
                    });
                }
            } catch (error) {
                console.error('Erreur lors de la mise à jour des tentatives :', error);
            }
        }

        // Vérifier si toutes les énigmes sont résolues
        let allCorrect = true;
        const updatedCorrectAnswers = { ...correctAnswers, [id]: isCorrect };
        for (const enigmeId of enigmeIds) {
            if (updatedCorrectAnswers[enigmeId] !== true) {
                allCorrect = false;
                break;
            }
        }

        if (allCorrect) {
            setEnigmeStatus('resolu');

            // Mise à jour du statut
            try {
                const configRef = doc(db, 'config', 'game');
                const configSnap = await getDoc(configRef);

                if (configSnap.exists()) {
                    const currentGameId = configSnap.data().current;
                    const groupRef = doc(db, 'games', currentGameId, 'groups', group.id);

                    // Mettre à jour le statut du groupe pour le niveau spécifique
                    await updateDoc(groupRef, {
                        [`enigmeStatus.${level}`]: 'resolu',
                    });
                }
            } catch (error) {
                console.error('Erreur lors de la mise à jour du statut :', error);
            }

            // Afficher la Snackbar pour la réussite
            setSnackbarMessage('Félicitations ! Vous avez résolu toutes les énigmes.');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
        } else if (newAttempts >= 3) {
            setEnigmeStatus('echoue');

            // Mise à jour du statut dans la base de données pour le niveau spécifique
            try {
                const configRef = doc(db, 'config', 'game');
                const configSnap = await getDoc(configRef);

                if (configSnap.exists()) {
                    const currentGameId = configSnap.data().current;
                    const groupRef = doc(db, 'games', currentGameId, 'groups', group.id);
                    await updateDoc(groupRef, {
                        [`enigmeStatus.${level}`]: 'echoue',
                    });
                }
            } catch (error) {
                console.error('Erreur lors de la mise à jour du statut :', error);
            }

            // Afficher la Snackbar pour les tentatives épuisées
            setSnackbarMessage('Vous avez utilisé toutes vos tentatives.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        } else {
            // Afficher la Snackbar pour une tentative incorrecte
            if (!isCorrect) {
                setSnackbarMessage('Réponse incorrecte, veuillez réessayer.');
                setSnackbarSeverity('warning');
                setSnackbarOpen(true);
            }
        }
    };

    if (loading) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: '40px',
                }}
            >
                <CircularProgress />
                <Typography variant="h6" sx={{ mt: 2 }}>
                    Chargement...
                </Typography>
            </Box>
        );
    }

    // Si le groupe ou l'équipe n'est pas authentifié, ne rien afficher ou un message
    if (!group || !team) {
        return null;
    }

    return (
        <Box
            sx={{
                mt: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 2,
            }}
        >
            <Typography variant="h4" gutterBottom>
                Enigmes - Niveau {level}
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
                Entrez les réponses aux énigmes et puzzles. Vous avez 3 tentatives au total pour ce niveau.
            </Typography>

            {/* Affichage des tentatives restantes */}
            <Box sx={{ display: 'flex', mb: 2 }}>
                {Array.from({ length: 3 }).map((_, index) => (
                    <Box
                        key={index}
                        sx={{
                            width: '20px',
                            height: '20px',
                            borderRadius: '50%',
                            backgroundColor: index < attempts ? 'red' : 'grey',
                            margin: '0 5px',
                        }}
                    />
                ))}
            </Box>

            {/* Champs de saisie pour les énigmes/puzzles */}
            <Box sx={{ width: '100%', maxWidth: '600px' }}>
                {enigmeIds.map((id) => (
                    <Paper
                        key={id}
                        elevation={3}
                        sx={{ p: 2, mb: 2, display: 'flex', alignItems: 'center' }}
                    >
                        <Typography variant="h6" sx={{ width: '100px', whiteSpace: 'nowrap' }}>
                            {isNaN(id) ? (id === 'A' ? 'Puzzle' : 'Tangram') : 'Énigme'} {id}
                        </Typography>
                        <TextField
                            variant="outlined"
                            value={userAnswers[id]}
                            onChange={(e) => handleInputChange(id, e.target.value)}
                            disabled={
                                enigmeStatus !== 'en_cours' ||
                                correctAnswers[id] === true ||
                                attempts >= 3
                            }
                            sx={{ flexGrow: 1, mr: 2 }}
                        />
                        {correctAnswers[id] === true ? (
                            <IconButton disabled>
                                <CheckIcon color="success" />
                            </IconButton>
                        ) : correctAnswers[id] === false ? (
                            <IconButton disabled>
                                <CloseIcon color="error" />
                            </IconButton>
                        ) : null}
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleValidate(id)}
                            disabled={
                                enigmeStatus !== 'en_cours' ||
                                correctAnswers[id] === true ||
                                attempts >= 3
                            }
                        >
                            Valider
                        </Button>
                    </Paper>
                ))}
            </Box>

            {/*/!* Affichage du statut *!/*/}
            {/*{enigmeStatus === 'resolu' && (*/}
            {/*    <Alert severity="success" sx={{ width: '100%', mt: 2 }}>*/}
            {/*        Vous avez résolu toutes les énigmes !*/}
            {/*    </Alert>*/}
            {/*)}*/}
            {/*{enigmeStatus === 'echoue' && (*/}
            {/*    <Alert severity="error" sx={{ width: '100%', mt: 2 }}>*/}
            {/*        Vous avez échoué. Vous n'avez plus de tentatives.*/}
            {/*    </Alert>*/}
            {/*)}*/}

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity}
                    sx={{ width: '100%' }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default Enigme;
