
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { TeamProvider } from './contexts/TeamContext';
import Main from './Main';
import {AdminProvider} from "./contexts/AdminContext"; // Assurez-vous d'importer MainApp

function App() {
    return (
        <AdminProvider>
            <TeamProvider>
                <Router>
                    <div className="App">
                        <Main />
                    </div>
                </Router>
            </TeamProvider>
        </AdminProvider>
    );
}

export default App;