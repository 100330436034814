// src/contexts/AdminContext.js

import React, { createContext, useState } from 'react';

export const AdminContext = createContext();

export function AdminProvider({ children }) {
    const [isAdminAuthenticated, setIsAdminAuthenticated] = useState(false);

    return (
        <AdminContext.Provider value={{ isAdminAuthenticated, setIsAdminAuthenticated }}>
            {children}
        </AdminContext.Provider>
    );
}
