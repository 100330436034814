import React from 'react';
import { Grid } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

function AttemptCircles({ attempts }) {
    const iconAnimation = {
        animation: 'fadeInOut 0.5s ease-in-out',
    };

    const renderIcon = (attempt, index) => {
        switch (attempt) {
            case true:
                return <CheckCircleIcon sx={{ ...iconAnimation, fontSize: '40px' }} color="success" />;
            case false:
                return <CancelIcon sx={{ ...iconAnimation, fontSize: '40px' }} color="error" />;
            case null:
            default:
                return <RemoveCircleIcon sx={{ ...iconAnimation, fontSize: '40px' }} color="primary" />;
        }
    };

    return (
        <Grid container spacing={2} justifyContent="center" alignItems="center">
            <style>
                {`
          @keyframes fadeInOut {
            0% { opacity: 0; }
            25% { opacity: 0.25; }
            50% { opacity: 0.5; }
            75% { opacity: 0.75; }
            100% { opacity: 1; }
          }
        `}
            </style>
            {attempts.map((attempt, index) => (
                <Grid item key={index}>
                    {renderIcon(attempt, index)}
                </Grid>
            ))}
        </Grid>
    );
}

export default AttemptCircles;
