// src/components/Bomb/Bomb.js

import React, { useState, useEffect, useContext } from 'react';
import { Box, Button, TextField, Typography, Alert, Snackbar, CircularProgress } from '@mui/material';
import { db } from '../../firebase';
import { doc, getDoc, updateDoc, increment } from 'firebase/firestore';
import { TeamContext } from '../../contexts/TeamContext';
import { bombCodes, bombPointReward } from '../../config/config';

function Bomb({ level }) {
    const { group, team } = useContext(TeamContext);
    const [codeInput, setCodeInput] = useState('');
    const [attempts, setAttempts] = useState([null, null, null]);
    const [bombStatus, setBombStatus] = useState('en_cours'); // 'en_cours', 'echoue', 'desactivee'
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // 'success' or 'error'
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [loading, setLoading] = useState(true);

    // Récupérer les tentatives du groupe pour le niveau spécifique lors du montage du composant
    useEffect(() => {
        const fetchGroupData = async () => {
            if (group) {
                try {
                    const configRef = doc(db, 'config', 'game');
                    const configSnap = await getDoc(configRef);

                    if (configSnap.exists()) {
                        const currentGameId = configSnap.data().current;
                        const groupRef = doc(db, 'games', currentGameId, 'groups', group.id);
                        const groupSnap = await getDoc(groupRef);

                        if (groupSnap.exists()) {
                            const groupData = groupSnap.data();

                            // Récupérer les tentatives et le statut pour le niveau spécifique
                            const attemptsFromDB = (groupData.attemptsBomb && groupData.attemptsBomb[level]) || [null, null, null];
                            setAttempts(attemptsFromDB);

                            const statusFromDB = (groupData.bombStatus && groupData.bombStatus[level]) || 'en_cours';
                            setBombStatus(statusFromDB);

                            setLoading(false);
                        }
                    }
                } catch (error) {
                    console.error('Erreur lors de la récupération des données du groupe :', error);
                    setLoading(false);
                }
            }
        };

        fetchGroupData();
    }, [group, level]);

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const handleValidate = async () => {
        const firstNullIndex = attempts.indexOf(null);

        const correctCode = bombCodes[group.id][level];

        if (codeInput !== correctCode) {
            console.log('Code incorrect');
            const newAttempts = [...attempts];
            if (firstNullIndex !== -1) {
                newAttempts[firstNullIndex] = false; // échec
                setAttempts(newAttempts);

                // Mise à jour des tentatives dans la base de données pour le niveau spécifique
                try {
                    const configRef = doc(db, 'config', 'game');
                    const configSnap = await getDoc(configRef);

                    if (configSnap.exists()) {
                        const currentGameId = configSnap.data().current;
                        const groupRef = doc(db, 'games', currentGameId, 'groups', group.id);
                        await updateDoc(groupRef, {
                            [`attemptsBomb.${level}`]: newAttempts,
                        });
                    }
                } catch (error) {
                    console.error('Erreur lors de la mise à jour des tentatives :', error);
                }
            }

            // Vérifier si les tentatives sont épuisées
            if (newAttempts.filter(a => a !== null).length >= 3) {
                setBombStatus('echoue');

                // Mise à jour du statut dans la base de données pour le niveau spécifique
                try {
                    const configRef = doc(db, 'config', 'game');
                    const configSnap = await getDoc(configRef);

                    if (configSnap.exists()) {
                        const currentGameId = configSnap.data().current;
                        const groupRef = doc(db, 'games', currentGameId, 'groups', group.id);
                        await updateDoc(groupRef, {
                            [`bombStatus.${level}`]: 'echoue',
                        });
                    }
                } catch (error) {
                    console.error('Erreur lors de la mise à jour du statut :', error);
                }

                // Afficher la Snackbar pour les tentatives épuisées
                setSnackbarMessage('Vous avez utilisé toutes vos tentatives. La bombe a explosé.');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            } else {
                // Afficher la Snackbar pour une tentative incorrecte
                setSnackbarMessage('Code incorrect, veuillez réessayer.');
                setSnackbarSeverity('warning');
                setSnackbarOpen(true);
            }
        } else {
            console.log('Bombe désactivée !');
            setBombStatus('desactivee');

            // Mettre à jour les tentatives localement
            const newAttempts = [...attempts];
            if (firstNullIndex !== -1) {
                newAttempts[firstNullIndex] = true; // réussite
                setAttempts(newAttempts);
            }

            // Mise à jour du score de l'équipe et du statut du groupe dans la base de données
            try {
                const configRef = doc(db, 'config', 'game');
                const configSnap = await getDoc(configRef);

                if (configSnap.exists()) {
                    const currentGameId = configSnap.data().current;
                    const groupRef = doc(db, 'games', currentGameId, 'groups', group.id);
                    const teamRef = doc(db, 'games', currentGameId, 'teams', group.teamId);

                    // Mettre à jour le statut du groupe pour le niveau spécifique
                    await updateDoc(groupRef, {
                        [`bombStatus.${level}`]: 'desactivee',
                        [`attemptsBomb.${level}`]: newAttempts,
                    });

                    // Ajouter des points au score de l'équipe
                    await updateDoc(teamRef, {
                        score: increment(bombPointReward),
                    });
                }
            } catch (error) {
                console.error('Erreur lors de la mise à jour du score et du statut :', error);
            }

            // Afficher la Snackbar pour la réussite
            setSnackbarMessage('Félicitations ! Vous avez désactivé la bombe.');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
        }
    };

    if (loading) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: '40px',
                }}
            >
                <CircularProgress />
                <Typography variant="h6" sx={{ mt: 2 }}>
                    Chargement...
                </Typography>
            </Box>
        );
    }

    // Si le groupe ou l'équipe n'est pas authentifié, ne rien afficher ou un message
    if (!group || !team) {
        return null;
    }

    return (
        <Box
            sx={{
                mt: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 2,
            }}
        >
            <Typography variant="body1" sx={{ mb: 2 }}>
                Entrez le code de désactivation pour désamorcer la bombe.
            </Typography>

            {/* Affichage des tentatives restantes */}
            <Box sx={{ display: 'flex', mb: 2 }}>
                {attempts.map((attempt, index) => (
                    <Box
                        key={index}
                        sx={{
                            width: '20px',
                            height: '20px',
                            borderRadius: '50%',
                            backgroundColor: attempt === true ? 'green' : attempt === false ? 'red' : 'grey',
                            margin: '0 5px',
                        }}
                    />
                ))}
            </Box>

            {/* Champ de saisie du code */}
            <TextField
                label="Code de désactivation"
                variant="outlined"
                value={codeInput}
                onChange={(e) => setCodeInput(e.target.value)}
                disabled={bombStatus !== 'en_cours'}
                sx={{ mb: 2, width: '300px' }}
            />

            {/* Bouton de validation */}
            <Button
                variant="contained"
                color="primary"
                onClick={handleValidate}
                disabled={bombStatus !== 'en_cours'}
                sx={{ mb: 2 }}
            >
                Valider
            </Button>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default Bomb;
