// src/components/Score/TeamCard.js

import { useEffect, useState } from "react";
import { Button, Card, CardContent, Grid, TextField, Typography } from "@mui/material";
import { db } from "../../firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";

function TeamCard({ team, isAdmin }) {
    const [scoreInput, setScoreInput] = useState(team.score);
    const [isUpdating, setIsUpdating] = useState(false);

    // Mettre à jour le scoreInput si le score change
    useEffect(() => {
        setScoreInput(team.score);
    }, [team.score]);

    // Fonction pour obtenir la couleur basée sur le nom de l'équipe
    const getTeamColor = (teamId) => {
        const teamColors = {
            green: '#4caf50', // Vert Matériel 500
            blue: '#2196f3', // Bleu Matériel 500
            yellow: '#ffeb3b', // Jaune Matériel 500
            red: '#f44336', // Rouge Matériel 500
        };

        return teamColors[teamId] || '#9e9e9e'; // Gris 500 par défaut si la couleur de l'équipe n'est pas trouvée
    };

    // Fonction pour capitaliser la première lettre
    const capitalizeFirstLetter = (string) =>
        string.charAt(0).toUpperCase() + string.slice(1);

    const handleScoreChange = (event) => {
        setScoreInput(event.target.value);
    };

    const handleScoreUpdate = async () => {
        setIsUpdating(true);
        try {
            // Mettre à jour le score de l'équipe dans Firestore
            const configRef = doc(db, 'config', 'game');
            const configSnap = await getDoc(configRef);

            if (configSnap.exists()) {
                const currentGameId = configSnap.data().current;
                const teamRef = doc(db, 'games', currentGameId, 'teams', team.id);

                await updateDoc(teamRef, {
                    score: parseInt(scoreInput),
                });

                setIsUpdating(false);
            } else {
                console.error('Aucun jeu actuel trouvé dans la configuration.');
                setIsUpdating(false);
            }
        } catch (error) {
            console.error('Erreur lors de la mise à jour du score :', error);
            setIsUpdating(false);
        }
    };

    const getTeamNameInFrench = (teamId) => {
        const teamNames = {
            green: 'Vert',
            blue: 'Bleu',
            yellow: 'Jaune',
            red: 'Rouge',
        };

        return teamNames[teamId] || capitalizeFirstLetter(teamId);
    };

    return (
        <Grid item xs={12} sm={6} md={3}>
            <Card
                sx={{
                    backgroundColor: getTeamColor(team.id),
                    color: 'white',
                }}
            >
                <CardContent>
                    <Typography variant="h5" component="div" align="center">
                        Équipe {getTeamNameInFrench(team.id)}
                    </Typography>

                    {/* Afficher le score actuel en texte non modifiable */}
                    <Typography
                        variant="h2"
                        component="div"
                        align="center"
                        sx={{ marginTop: '16px' }}
                    >
                        {team.score}
                    </Typography>

                    {/*/!* Afficher les groupes de l'équipe *!/*/}
                    {/*<Typography variant="h6" component="div" align="center" sx={{ marginTop: '16px' }}>*/}
                    {/*    Groupes :*/}
                    {/*</Typography>*/}
                    {/*<List>*/}
                    {/*    {team.groups && team.groups.map((group) => (*/}
                    {/*        <ListItem key={group.id} sx={{ color: 'white' }}>*/}
                    {/*            {capitalizeFirstLetter(group.id.split('-')[1])}*/}
                    {/*        </ListItem>*/}
                    {/*    ))}*/}
                    {/*</List>*/}

                    {isAdmin && (
                        <>
                            <TextField
                                variant="filled"
                                type="number"
                                value={scoreInput}
                                onChange={handleScoreChange}
                                fullWidth
                                sx={{
                                    backgroundColor: 'rgba(255, 255, 255, 0.15)',
                                    borderRadius: '4px',
                                    input: { color: 'white' },
                                    marginTop: '16px',
                                }}
                                inputProps={{ min: 0 }}
                            />
                            <Button
                                variant="contained"
                                onClick={handleScoreUpdate}
                                disabled={isUpdating}
                                fullWidth
                                sx={{
                                    marginTop: '16px',
                                    backgroundColor: 'rgba(0, 0, 0, 0.2)',
                                    color: 'white',
                                    '&:hover': {
                                        backgroundColor: 'rgba(0, 0, 0, 0.3)',
                                    },
                                }}
                            >
                                {isUpdating ? 'Mise à jour...' : 'Modifier le score'}
                            </Button>
                        </>
                    )}
                </CardContent>
            </Card>
        </Grid>
    );
}

export default TeamCard;
