// src/components/Dingbats/Dingbats.js

import React, { useState, useEffect, useContext } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { db } from '../../firebase';
import { doc, getDoc, updateDoc, increment } from 'firebase/firestore';
import { TeamContext } from '../../contexts/TeamContext';
import DingbatItem from './DingbatItem';
import {dingbatsAnswers, dingbatsPointReward} from '../../config/config'; // Import des réponses
import { stringSimilarity } from "string-similarity-js";

function Dingbats({ level }) {
    const { group, team } = useContext(TeamContext);
    const [dingbatsData, setDingbatsData] = useState([]);
    const [attempts, setAttempts] = useState({});
    const [answers, setAnswers] = useState({});

    useEffect(() => {
        if (group) {
            const fetchAttempts = async () => {
                try {
                    const configRef = doc(db, 'config', 'game');
                    const configSnap = await getDoc(configRef);

                    if (configSnap.exists()) {
                        const currentGameId = configSnap.data().current;
                        const groupRef = doc(db, 'games', currentGameId, 'groups', group.id);
                        const groupSnap = await getDoc(groupRef);

                        if (groupSnap.exists()) {
                            const groupData = groupSnap.data();
                            // Récupérer les tentatives pour le niveau spécifique
                            const dingbatsAttempts = (groupData.dingbatsAttempts && groupData.dingbatsAttempts[level]) || {};
                            setAttempts(dingbatsAttempts);
                        }
                    }
                } catch (error) {
                    console.error('Erreur lors de la récupération des tentatives de dingbats :', error);
                }
            };

            fetchAttempts();
        }
    }, [group, level]); // Ajouter 'level' comme dépendance

    // Charger les dingbats
    useEffect(() => {
        // Initialiser les données des dingbats
        const dingbatsArray = [];
        const dingbatsStart = level === '1' ? 1 : 17;
        const dingbatsEnd = level === '1' ? 16 : 28;
        for (let i = dingbatsStart; i <= dingbatsEnd; i++) {
            dingbatsArray.push({
                id: i,
                imageUrl: `${process.env.PUBLIC_URL}/assets/dingbats/${i}.gif`,
            });
        }
        setDingbatsData(dingbatsArray);
    }, [level]);

    // Fonction pour normaliser les réponses
    const normalizeAnswer = (str) => {
        return str
            .toLowerCase()
            .normalize('NFD') // Normalise les caractères accentués
            .replace(/[\u0300-\u036f]/g, '') // Supprime les diacritiques
            .replace(/[^a-z0-9]/g, ''); // Supprime les caractères spéciaux
    };

    const handleValidate = async (dingbatId) => {
        const userAnswer = answers[dingbatId] || '';
        const correctAnswer = dingbatsAnswers[dingbatId];

        // Normaliser les réponses
        const normalizedUserAnswer = normalizeAnswer(userAnswer);

        // Si vous avez plusieurs réponses possibles
        const possibleAnswers = Array.isArray(correctAnswer) ? correctAnswer : [correctAnswer];

        // Initialiser la variable pour déterminer si la réponse est correcte
        let isCorrect = false;

        // Parcourir les réponses possibles
        for (let answer of possibleAnswers) {
            const normalizedCorrectAnswer = normalizeAnswer(answer);

            // Calculer la similarité avec string-similarity-js
            const similarityScore = stringSimilarity(normalizedUserAnswer, normalizedCorrectAnswer);

            // Définir un seuil d'acceptation (par exemple, 0.8 pour 80% de similarité)
            const minSimilarity = 0.8;

            if (similarityScore >= minSimilarity) {
                isCorrect = true;
                break;
            }
        }

        // Mettre à jour l'état local
        setAttempts((prev) => ({
            ...prev,
            [dingbatId]: isCorrect,
        }));

        // Mettre à jour la base de données
        try {
            const configRef = doc(db, 'config', 'game');
            const configSnap = await getDoc(configRef);

            if (configSnap.exists()) {
                const currentGameId = configSnap.data().current;
                const groupRef = doc(db, 'games', currentGameId, 'groups', group.id);

                // Mettre à jour les tentatives du groupe pour le niveau spécifique
                await updateDoc(groupRef, {
                    [`dingbatsAttempts.${level}.${dingbatId}`]: isCorrect,
                });

                // Si la réponse est correcte, ajouter point au score de l'équipe
                if (isCorrect) {
                    const teamRef = doc(db, 'games', currentGameId, 'teams', group.teamId);
                    await updateDoc(teamRef, {
                        score: increment(dingbatsPointReward),
                    });
                }
            }
        } catch (error) {
            console.error('Erreur lors de la mise à jour des tentatives de dingbats :', error);
        }
    };

    // Gestion des changements de réponse
    const handleAnswerChange = (dingbatId, value) => {
        setAnswers((prev) => ({
            ...prev,
            [dingbatId]: value,
        }));
    };

    if (!group || !team) {
        return null;
    }

    return (
        <Box sx={{ padding: 2 }}>
            <Typography variant="h5" align="center" gutterBottom>
                Vous n'avez qu'un essai par dingbat
            </Typography>
            <Grid container spacing={4}>
                {dingbatsData.map((dingbat) => (
                    <DingbatItem
                        key={dingbat.id}
                        dingbat={dingbat}
                        attempt={attempts[dingbat.id]}
                        answer={answers[dingbat.id] || ''}
                        onAnswerChange={handleAnswerChange}
                        onValidate={handleValidate}
                    />
                ))}
            </Grid>
        </Box>
    );
}

export default Dingbats;
