import React, { useState } from 'react';
import {
    Grid,
    Card,
    CardMedia,
    TextField,
    Button,
    IconButton,
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { CheckCircle, Cancel, Close as CloseIcon } from '@mui/icons-material';

function DingbatItem({ dingbat, attempt, answer, onAnswerChange, onValidate }) {
    const [openDialog, setOpenDialog] = useState(false); // État pour gérer la boîte de dialogue

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleInputChange = (e) => {
        onAnswerChange(dingbat.id, e.target.value);
    };

    const handleValidateClick = () => {
        onValidate(dingbat.id);
    };

    const handleImageClick = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    return (
        <Grid item xs={12} md={6}>
            <Card
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    alignItems: { xs: 'flex-start', md: 'center' },
                    padding: 2,
                }}
            >
                <CardMedia
                    component="img"
                    image={dingbat.imageUrl}
                    alt={`Dingbat ${dingbat.id}`}
                    onClick={handleImageClick}
                    sx={{
                        width: {
                            xs: '100%',
                            sm: '100%',
                            md: 200,
                            lg: 350,
                            xl: 400,
                        },
                        height: 'auto',
                        marginRight: { xs: 0, md: 2 },
                        marginBottom: { xs: 2, md: 0 },
                        cursor: 'pointer',
                        flexShrink: 0,
                    }}
                />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        alignItems: { xs: 'flex-start', md: 'center' },
                        width: '100%',
                    }}
                >
                    <TextField
                        label="Votre réponse"
                        variant="outlined"
                        value={answer}
                        onChange={handleInputChange}
                        disabled={attempt !== undefined}
                        fullWidth
                        sx={{
                            mb: { xs: 2, md: 0 },
                            flexGrow: 1,
                            maxWidth: { md: '300px', lg: '350px', xl: '400px' },
                        }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleValidateClick}
                        disabled={attempt !== undefined}
                        sx={{ ml: { xs: 0, md: 2 }, mb: { xs: 2, md: 0 } }}
                    >
                        Valider
                    </Button>
                    {attempt !== undefined && (
                        <IconButton sx={{ ml: { xs: 0, md: 2 } }}>
                            {attempt ? (
                                <CheckCircle color="success" />
                            ) : (
                                <Cancel color="error" />
                            )}
                        </IconButton>
                    )}
                </Box>
            </Card>

            {/* Boîte de dialogue pour afficher l'image en taille maximale */}
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                maxWidth={false}
                fullScreen={fullScreen}
                sx={{
                    '& .MuiDialog-paper': {
                        margin: 0,
                        width: '100%',
                        maxWidth: 'none',
                    },
                }}
            >
                <DialogTitle sx={{ m: 0, p: 2 }}>
                    {`Dingbat ${dingbat.id}`}
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseDialog}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent
                    dividers
                    sx={{
                        padding: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                    }}
                >
                    <img
                        src={dingbat.imageUrl}
                        alt={`Dingbat ${dingbat.id}`}
                        style={{
                            width: '100%',
                            height: 'auto',
                            maxHeight: 'calc(100vh - 64px)', // Ajuste la hauteur maximale
                        }}
                    />
                </DialogContent>
            </Dialog>
        </Grid>
    );
}

export default DingbatItem;
