// src/components/Sudoku/Sudoku.js

import React, { useState, useEffect, useContext } from 'react';
import { solvedSudoku1, solvedSudoku2, sudokuPointReward } from '../../config/config';
import { Box, Button, Alert, Snackbar } from '@mui/material';
import AttemptCircles from './AttemptCircles';
import SudokuGrid from './SudokuGrid';
import { doc, getDoc, updateDoc, increment } from 'firebase/firestore';
import { db } from '../../firebase';
import { TeamContext } from '../../contexts/TeamContext';

function Sudoku({ level }) {
    const { group, team } = useContext(TeamContext);
    const [currentSudoku, setCurrentSudoku] = useState([
        ['', '', ''],
        ['', '', ''],
        ['', '', ''],
    ]);

    const [attempts, setAttempts] = useState([null, null, null]);
    const [sudokuStatus, setSudokuStatus] = useState('en_cours'); // 'en_cours', 'echoue', 'resolu'
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // 'success' or 'error'
    const [snackbarMessage, setSnackbarMessage] = useState('');

    // Récupérer les tentatives du groupe pour le niveau spécifique lors du montage du composant
    useEffect(() => {
        const fetchGroupData = async () => {
            if (group) {
                try {
                    const configRef = doc(db, 'config', 'game');
                    const configSnap = await getDoc(configRef);

                    if (configSnap.exists()) {
                        const currentGameId = configSnap.data().current;
                        const groupRef = doc(db, 'games', currentGameId, 'groups', group.id);
                        const groupSnap = await getDoc(groupRef);

                        if (groupSnap.exists()) {
                            const groupData = groupSnap.data();

                            // Récupérer les tentatives et le statut pour le niveau spécifique
                            const attemptsFromDB = (groupData.attemptsSudoku && groupData.attemptsSudoku[level]) || [null, null, null];
                            setAttempts(attemptsFromDB);

                            const statusFromDB = (groupData.sudokuStatus && groupData.sudokuStatus[level]) || 'en_cours';
                            setSudokuStatus(statusFromDB);
                        }
                    }
                } catch (error) {
                    console.error('Erreur lors de la récupération des données du groupe :', error);
                }
            }
        };

        fetchGroupData();
    }, [group, level]); // Ajouter 'level' comme dépendance

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    // Fonction de validation du Sudoku
    const handleValidate = async () => {
        const firstNullIndex = attempts.indexOf(null);

        const solvedSudoku = level === '1' ? solvedSudoku1 : solvedSudoku2;

        if (JSON.stringify(currentSudoku) !== JSON.stringify(solvedSudoku)) {
            console.log('Grille incorrecte');
            const newAttempts = [...attempts];
            if (firstNullIndex !== -1) {
                newAttempts[firstNullIndex] = false; // échec
                setAttempts(newAttempts);

                // Mise à jour des tentatives dans la base de données pour le niveau spécifique
                try {
                    const configRef = doc(db, 'config', 'game');
                    const configSnap = await getDoc(configRef);

                    if (configSnap.exists()) {
                        const currentGameId = configSnap.data().current;
                        const groupRef = doc(db, 'games', currentGameId, 'groups', group.id);
                        await updateDoc(groupRef, {
                            [`attemptsSudoku.${level}`]: newAttempts,
                        });
                    }
                } catch (error) {
                    console.error('Erreur lors de la mise à jour des tentatives :', error);
                }
            }

            // Vérifier si les tentatives sont épuisées
            if (newAttempts.filter(a => a !== null).length >= 3) {
                setSudokuStatus('echoue');

                // Mise à jour du statut dans la base de données pour le niveau spécifique
                try {
                    const configRef = doc(db, 'config', 'game');
                    const configSnap = await getDoc(configRef);

                    if (configSnap.exists()) {
                        const currentGameId = configSnap.data().current;
                        const groupRef = doc(db, 'games', currentGameId, 'groups', group.id);
                        await updateDoc(groupRef, {
                            [`sudokuStatus.${level}`]: 'echoue',
                        });
                    }
                } catch (error) {
                    console.error('Erreur lors de la mise à jour du statut :', error);
                }

                // Afficher la Snackbar pour les tentatives épuisées
                setSnackbarMessage('Vous avez utilisé toutes vos tentatives.');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            } else {
                // Afficher la Snackbar pour une tentative incorrecte
                setSnackbarMessage('Grille incorrecte, veuillez réessayer.');
                setSnackbarSeverity('warning');
                setSnackbarOpen(true);
            }
        } else {
            console.log('Sudoku résolu !');
            setSudokuStatus('resolu');

            // Mettre à jour les tentatives localement
            const newAttempts = [...attempts];
            if (firstNullIndex !== -1) {
                newAttempts[firstNullIndex] = true; // résolu
                setAttempts(newAttempts);
            }

            // Mise à jour du score de l'équipe et du statut du groupe dans la base de données
            try {
                const configRef = doc(db, 'config', 'game');
                const configSnap = await getDoc(configRef);

                if (configSnap.exists()) {
                    const currentGameId = configSnap.data().current;
                    const groupRef = doc(db, 'games', currentGameId, 'groups', group.id);
                    const teamRef = doc(db, 'games', currentGameId, 'teams', group.teamId);

                    // Mettre à jour le statut du groupe pour le niveau spécifique
                    await updateDoc(groupRef, {
                        [`sudokuStatus.${level}`]: 'resolu',
                        [`attemptsSudoku.${level}`]: newAttempts,
                    });

                    // Ajouter des points au score de l'équipe
                    await updateDoc(teamRef, {
                        score: increment(sudokuPointReward),
                    });
                }
            } catch (error) {
                console.error('Erreur lors de la mise à jour du score et du statut :', error);
            }

            // Afficher la Snackbar pour la réussite
            setSnackbarMessage('Félicitations ! Vous avez résolu le Sudoku.');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
        }
    };

    // Si le groupe ou l'équipe n'est pas authentifié, ne rien afficher ou un message
    if (!group || !team) {
        return null;
    }

    return (
        <Box sx={{ mt: 0 }}>
            <AttemptCircles attempts={attempts} />
            <SudokuGrid
                sudoku={currentSudoku}
                setSudoku={setCurrentSudoku}
                disabled={sudokuStatus !== 'en_cours'}
                level={level}
            />
            <Button
                type="submit"
                variant="contained"
                sx={{ mt: 2 }}
                onClick={handleValidate}
                disabled={sudokuStatus !== 'en_cours'}
            >
                Valider
            </Button>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default Sudoku;
