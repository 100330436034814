// src/components/TeamCodeModal/TeamCodeModal.js

import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    Backdrop,
    CircularProgress,
    Typography,
} from '@mui/material';
import { collection, doc, getDoc, onSnapshot, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';

function TeamCodeModal({ open, onGroupAuthenticated }) {
    const [code, setCode] = useState('');
    const [error, setError] = useState('');
    const [waitingForGame, setWaitingForGame] = useState(false);

    useEffect(() => {
        let unsubscribe;

        const checkCurrentGame = () => {
            try {
                const configRef = doc(db, 'config', 'game');
                unsubscribe = onSnapshot(configRef, (configSnap) => {
                    if (configSnap.exists() && configSnap.data().current) {
                        setWaitingForGame(false);
                    } else {
                        setWaitingForGame(true);
                    }
                });
            } catch (err) {
                console.error('Erreur lors de la vérification de la partie en cours :', err);
                setError('Une erreur est survenue. Veuillez réessayer plus tard.');
            }
        };

        checkCurrentGame();

        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, []);

    const handleCodeChange = (event) => {
        setCode(event.target.value.toUpperCase());
    };

    const handleAuthenticate = async () => {
        try {
            console.log('Code saisi :', code);
            const configRef = doc(db, 'config', 'game');
            const configSnap = await getDoc(configRef);

            if (configSnap.exists() && configSnap.data().current) {
                const currentGameId = configSnap.data().current;

                // Rechercher le groupe avec le code saisi
                const groupsRef = collection(db, 'games', currentGameId, 'groups');
                const q = query(groupsRef, where('code', '==', code));
                const querySnapshot = await getDocs(q);

                console.log('Nombre de groupes trouvés :', querySnapshot.size);

                if (!querySnapshot.empty) {
                    const groupDoc = querySnapshot.docs[0];
                    const groupData = { id: groupDoc.id, ...groupDoc.data() };

                    console.log('Groupe authentifié :', groupData);

                    // Appeler la fonction de rappel avec les données du groupe
                    onGroupAuthenticated(groupData);
                } else {
                    setError('Code invalide, veuillez réessayer.');
                }
            } else {
                // Aucune partie en cours
                setWaitingForGame(true);
            }
        } catch (err) {
            console.error('Erreur lors de l\'authentification du groupe :', err);
            setError('Une erreur est survenue lors de l\'authentification.');
        }
    };


    return (
        <Dialog
            open={open}
            onClose={() => {}}
            disableEscapeKeyDown
            aria-labelledby="team-code-dialog-title"
            BackdropComponent={Backdrop}
            BackdropProps={{
                style: { backdropFilter: 'blur(5px)' },
            }}
        >
            {waitingForGame ? (
                <DialogContent
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: '40px',
                    }}
                >
                    <CircularProgress />
                    <Typography variant="h6" sx={{ mt: 2 }}>
                        En attente du lancement de la partie...
                    </Typography>
                </DialogContent>
            ) : (
                <>
                    <DialogTitle id="team-code-dialog-title">Entrez le code de votre groupe</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            label="Code de groupe"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={code}
                            onChange={handleCodeChange}
                            error={Boolean(error)}
                            helperText={error}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleAuthenticate} variant="contained" color="primary">
                            Valider
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
}

export default TeamCodeModal;
