export const groupCodes = {
    'green-alpha': 'PHOENIX',
    'green-beta': 'SELENE',
    'blue-alpha': 'ANTHEA',
    'blue-beta': 'ZEUS',
    'yellow-alpha': 'PLUTON',
    'yellow-beta': 'PROXIMA',
    'red-alpha': 'JAMES',
    'red-beta': 'THOMAS',
};

export const solvedSudoku1 = [
    ["2", "9", "7"],
    ["3", "1", "6"],
    ["8", "4", "5"]
];

export const solvedSudoku2 = [
    ["3", "9", "2"],
    ["6", "1", "8"],
    ["5", "4", "7"]
];

export const sudokuPointReward = 15;

export const dingbatsAnswers = {
    1: "Joindre l'utile à l'agréable",
    2: "Arc en ciel",
    3: "Couper la poire en deux",
    4: "Petit suisse",
    5: "L'arbre qui cache la foret",
    6: "Fausse note",
    7: "Saucce Hollandaise",
    8: "Trou de mémoire",
    9: "Du plomb dans l'aile",
    10: "Lire entre les lignes",
    11: "La parole est d’argent, le silence est d’or",
    12: "Plus de peur que de mal",
    13: "Ronde de nuit",
    14: "Un joli sourire",
    15: "Quatre-quarts à l'orange",
    16: "Lumière tamisée",
    17: "Ça passe ou ça casse",
    18: "Irréversible",
    19: "Avoir les yeux plus gros que le ventre",
    20: "Ça t'en bouche un coin",
    21: "Vivre au grand air",
    22: "Biodégradable",
    23: "Un carnet de notes",
    24: "Le jeu en vaut la chandelle",
    25: "Cité universitaire",
    26: "Le petit bonhomme en mousse",
    27: "Point trop n'en faut",
    28: "Joue contre joue"
};


export const dingbatsPointReward = 1;

export const bombCodes = {
    'green-alpha': {
        '1': 'GA1-1234',
        '2': 'GA2-5678',
    },
    'green-beta': {
        '1': 'GB1-2345',
        '2': 'GB2-6789',
    },
    'blue-alpha': {
        '1': 'BA1-3456',
        '2': 'BA2-7890',
    },
    'blue-beta': {
        '1': 'BB1-4567',
        '2': 'BB2-8901',
    },
    'yellow-alpha': {
        '1': 'YA1-5678',
        '2': 'YA2-9012',
    },
    'yellow-beta': {
        '1': 'YB1-6789',
        '2': 'YB2-0123',
    },
    'red-alpha': {
        '1': 'RA1-7890',
        '2': 'RA2-1234',
    },
    'red-beta': {
        '1': 'RB1-8901',
        '2': 'RB2-2345',
    },
};

export const bombPointReward = 15;

export const codenameData = {
    '1': {
        value: 'Avenir',
        wordsToFind: ['Anticipation', 'Futur', 'Prospective'],
        gridWords: [
            'Anticipation', 'Futur', 'Prospective', 'Galaxie', 'Fusée', 'Planète', 'Voyage', 'Astéroïde', 'Étoile', 'Comète',
            'Satellite', 'Atmosphère', 'Science', 'Vaisseau', 'Mars', 'Cosmos', 'Orbite', 'Cosmonaute', 'Propulsion', 'Vide',
            'Energie', 'Univers', 'Terre', 'Aérospatial', 'Mystère',
        ],
    },
    '2': {
        value: 'Durabilité',
        wordsToFind: ['Préservation', 'Respect', 'Protection'],
        gridWords: [
            'Préservation', 'Respect', 'Protection', 'Gravité', 'Météorite', 'Appropriation', 'Domination', 'Fusion',
            'Station', 'Exploration', 'Vide', 'Lune', 'Sonde', 'Ingénierie', 'Capsule', 'Modification', 'Voie lactée',
            'Atmosphère', 'Vortex', 'Environnement', 'Isolation', 'Vitesse', 'Hélium', 'Boussole', 'Adaptation',
        ],
    },
    '3': {
        value: 'Ecologie',
        wordsToFind: ['Biosphère', 'Environnemental', 'Ecosystème'],
        gridWords: [
            'Biosphère', 'Environnemental', 'Ecosystème', 'Microgravité', 'Astre', 'Pression', 'Carbone', 'Météore',
            'Orbite', 'Gravité', 'Équateur', 'Rayonnement', 'Module', 'Pluton', 'Terre', 'Champ magnétique', 'Exoplanète',
            'Galaxie', 'Ultraviolet', 'Fusée', 'Compas', 'Éclipse', 'Trajectoire', 'Hublot', 'Cratère',
        ],
    },
    '4': {
        value: 'Innovation',
        wordsToFind: ['Nouveauté', 'Invention', 'Changement'],
        gridWords: [
            'Nouveauté', 'Invention', 'Changement', 'Propulseur', 'Astronaute', 'Capsule', 'Gravité', 'Poussière',
            'Lancement', 'Boussole', 'Observatoire', 'Parabole', 'Lune', 'Comète', 'Fusion', 'Inertie', 'Tuyère',
            'Périscope', 'Neutrino', 'Calcul', 'Pluton', 'Antenne', 'Électron', 'Système', 'Générateur',
        ],
    },
    '5': {
        value: 'Adaptabilité',
        wordsToFind: ['Flexibilité', 'Souplesse', 'Modularité'],
        gridWords: [
            'Flexibilité', 'Souplesse', 'Modularité', 'Astronaute', 'Complicité', 'Lune', 'Fatigue', 'Recherche',
            'Musculature', 'Décalage', 'Orbite', 'Propulsion', 'Capsule', 'Symétrie', 'Arithmétique', 'Météorite',
            'Trajectoire', 'Balistique', 'Étoile', 'Gravité', 'Appontage', 'Sonde', 'Rencontre', 'Assemblage', 'Périodicité',
        ],
    },
    '6': {
        value: 'Excellence',
        wordsToFind: ['Perfection', 'Précision', 'Performance'],
        gridWords: [
            'Perfection', 'Précision', 'Performance', 'Collaboration', 'Vision', 'Grandeur', 'Splendeur', 'Beauté',
            'Procrastination', 'Déviation', 'Assimilation', 'Traduction', 'Colorisation', 'Gravité', 'Astéroïde',
            'Nébuleuse', 'Capsule', 'Comète', 'Radiations', 'Sonde', 'Lune', 'Météorite', 'Trajectoire', 'Atmosphère',
            'Étoile',
        ],
    },
    '7': {
        value: 'Collaboration',
        wordsToFind: ['Association', 'Coopération', 'Concours'],
        gridWords: [
            'Association', 'Coopération', 'Concours', 'Jubilation', 'Sublimation', 'Décantation', 'Exaspération', 'Prime',
            'Objectif', 'Récompense', 'Découverte', 'Oubli', 'Cabriole', 'Astéroïde', 'Pulsar', 'Satellite', 'Orbite',
            'Météorite', 'Bistouri', 'Explosif', 'Tuyère', 'Capsule', 'Gravité', 'Myopie', 'Respirable',
        ],
    },
    '8': {
        value: 'Autonomie',
        wordsToFind: ['Indépendance', 'Souveraineté', 'Libre'],
        gridWords: [
            'Indépendance', 'Souveraineté', 'Libre', 'Joyeux', 'Tranquille', 'Calme', 'Sérieux', 'Redoutable', 'Complice',
            'Audacieux', 'Silencieux', 'Royal', 'Associé', 'Nébuleuse', 'Gravité', 'Étoile', 'Trajectoire', 'Astronaute',
            'Comète', 'Déplacé', 'Sublime', 'Remarquable', 'Grotesque', 'Imprévisible', 'Télescope',
        ],
    },
    '9': {
        value: 'Adaptabilité',
        wordsToFind: ['Flexibilité', 'Souplesse', 'Modularité'],
        gridWords: [
            'Flexibilité', 'Souplesse', 'Modularité', 'Astronaute', 'Complicité', 'Lune', 'Fatigue', 'Recherche',
            'Musculature', 'Décalage', 'Orbite', 'Propulsion', 'Capsule', 'Symétrie', 'Arithmétique', 'Météorite',
            'Trajectoire', 'Balistique', 'Étoile', 'Gravité', 'Appontage', 'Sonde', 'Rencontre', 'Assemblage', 'Périodicité',
            'Répétabilité', 'Dureté', 'Imagination', 'Créativité', 'Cosmos', 'Poussière', 'Tempête', 'Hublot', 'Énergie',
            'Équateur', 'Champ magnétique',
        ],
    },
    '10': {
        value: 'Excellence',
        wordsToFind: ['Perfection', 'Précision', 'Performance'],
        gridWords: [
            'Perfection', 'Précision', 'Performance', 'Collaboration', 'Vision', 'Grandeur', 'Splendeur', 'Beauté',
            'Procrastination', 'Déviation', 'Assimilation', 'Traduction', 'Colorisation', 'Gravité', 'Astéroïde',
            'Nébuleuse', 'Capsule', 'Comète', 'Radiations', 'Sonde', 'Lune', 'Météorite', 'Trajectoire', 'Atmosphère',
            'Étoile', 'Galaxie', 'Propulsion', 'Fusée', 'Soucoupe', 'Cratère', 'Décision', 'Contrôle', 'Position',
            'Exigence', 'Malveillance', 'Astéroïdes',
        ],
    },
    '11': {
        value: 'Collaboration',
        wordsToFind: ['Association', 'Coopération', 'Concours'],
        gridWords: [
            'Association', 'Coopération', 'Concours', 'Jubilation', 'Sublimation', 'Décantation', 'Exaspération', 'Prime',
            'Objectif', 'Récompense', 'Découverte', 'Oubli', 'Cabriole', 'Astéroïde', 'Pulsar', 'Satellite', 'Orbite',
            'Météorite', 'Bistouri', 'Explosif', 'Tuyère', 'Capsule', 'Gravité', 'Myopie', 'Respirable', 'Trainée',
            'Soleil', 'Galaxie', 'Lancement', 'Nébuleuse', 'Astronaute', 'Planète', 'Module', 'Téléscope', 'Eclipse',
            'Propulsion',
        ],
    },
    '12': {
        value: 'Autonomie',
        wordsToFind: ['Indépendance', 'Souveraineté', 'Libre'],
        gridWords: [
            'Indépendance', 'Souveraineté', 'Libre', 'Joyeux', 'Tranquille', 'Calme', 'Sérieux', 'Redoutable', 'Complice',
            'Audacieux', 'Silencieux', 'Royal', 'Associé', 'Nébuleuse', 'Gravité', 'Étoile', 'Trajectoire', 'Astronaute',
            'Comète', 'Déplacé', 'Sublime', 'Remarquable', 'Grotesque', 'Imprévisible', 'Télescope', 'Astéroïde', 'Orbite',
            'Vaisseau', 'Tempête', 'Radiations', 'Vortex',  'Atmosphère', 'Pluton', 'Galaxie', 'Pression', 'Parabole',
        ],
    },
};

// Attribution des grilles aux équipes et niveaux
export const codenameAssignments = {
    'green': {
        '1': '2', // Niveau 1, grille 2
        // '2': '6', // Niveau 2, grille 6
         '2': '10',
    },
    'blue': {
        '1': '3',
        // '2': '7',
         '2': '11',
    },
    'yellow': {
        '1': '4',
        // '2': '8',
         '2': '12',
    },
    'red': {
        '1': '1',
        // '2': '5',
         '2': '9',
    },
};

// Points attribués pour la réussite du jeu Codename
export const codenamePointReward = 15;

// Réponses pour le jeu Enigme
export const enigmeAnswers = {
    '1': ['Soucoupe', 3], // [Réponse, Points]
    '2': ['Station', 3],
    '3': ['Terre', 3],
    '4': ['Pousser', 3],
    '5': ['Grain', 3],
    '6': ['Champ', 3],
    '7': ['Fane', 3],
    '8': ['Croissant', 3],
    // Facile
    '9': ['Fusée', 1],
    '10': ['Lune', 1],
    '11': ['Agriculture', 1],
    '12': ['La conquête de la lune', 1],
    '13': ['Astronaute', 1],
    '14': ['Exploration', 1],
    '15': ['Survie', 1],
    '16': ['Oasis', 1],
    'A': ["Hotesse de l'air", 5], // Puzzle
    'B': ['8745', 5], // Tangram
};

// Organisation des énigmes/puzzles par niveau
export const enigmeLevels = {
    '1': ['9', '10', '11', '12', '1', '2', '3', '4', 'A'],
    '2': ['13', '14', '15', '16', '5', '6', '7', '8', 'B'],
};
