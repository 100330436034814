// src/components/Codename/Codename.js

import React, { useState, useEffect, useContext } from 'react';
import {
    Box,
    Button,
    Typography,
    Alert,
    Snackbar,
    Card,
    CardContent,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { db } from '../../firebase';
import { doc, getDoc, updateDoc, increment } from 'firebase/firestore';
import { TeamContext } from '../../contexts/TeamContext';
import { codenameData, codenameAssignments, codenamePointReward } from '../../config/config';

function Codename({ level }) {
    const { group, team } = useContext(TeamContext);
    const [gridWords, setGridWords] = useState([]);
    const [wordsToFind, setWordsToFind] = useState([]);
    const [selectedWords, setSelectedWords] = useState([]);
    const [attempts, setAttempts] = useState([]);
    const [codenameStatus, setCodenameStatus] = useState('en_cours'); // 'en_cours', 'echoue', 'resolu'
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // 'success' or 'error'
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const [gridId, setGridId] = useState(null);

    // Utiliser le thème MUI pour les media queries
    const theme = useTheme();

    // Utiliser les media queries pour ajuster le nombre de colonnes
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));// Extra-small screens
    const isSm = useMediaQuery(theme.breakpoints.only('sm')); // Small screens
    const isMd = useMediaQuery(theme.breakpoints.only('md')); // Medium screens
    const isLg = useMediaQuery(theme.breakpoints.only('lg')); // Large screens
    const isXl = useMediaQuery(theme.breakpoints.up('xl'));   // Extra-large screens

    // Déterminer le nombre de colonnes en fonction du niveau
    const baseColumns = level === '1' ? 5 : 6;

    // Calculer le nombre de colonnes en fonction de la taille de l'écran
    let columns = baseColumns;

    if (isXs) {
        columns = 1;
    } else if (isSm) {
        columns = Math.min(2, baseColumns);
    } else if (isMd) {
        columns = Math.min(3, baseColumns);
    } else if (isLg) {
        columns = baseColumns
    } else {
        columns = baseColumns; // Utilise 5 ou 6 colonnes selon le niveau
    }

    // Récupérer les données de la grille pour le groupe et le niveau actuels
    useEffect(() => {
        const fetchGridData = async () => {
            if (group && team) {
                try {
                    // Obtenir l'ID de la grille pour le groupe et le niveau actuels
                    const teamId = group.teamId;
                    const gridNumber = codenameAssignments[teamId][level];
                    setGridId(gridNumber);

                    // Obtenir les données de la grille
                    const gridData = codenameData[gridNumber];
                    setGridWords(shuffleArray(gridData.gridWords));
                    setWordsToFind(gridData.wordsToFind);

                    setLoading(false);
                } catch (error) {
                    console.error('Erreur lors de la récupération des données de la grille :', error);
                    setLoading(false);
                }
            }
        };

        fetchGridData();
    }, [group, team, level]);

    // Récupérer les tentatives du groupe pour le niveau spécifique lors du montage du composant
    useEffect(() => {
        const fetchGroupData = async () => {
            if (group) {
                try {
                    const configRef = doc(db, 'config', 'game');
                    const configSnap = await getDoc(configRef);

                    if (configSnap.exists()) {
                        const currentGameId = configSnap.data().current;
                        const groupRef = doc(db, 'games', currentGameId, 'groups', group.id);
                        const groupSnap = await getDoc(groupRef);

                        if (groupSnap.exists()) {
                            const groupData = groupSnap.data();

                            // Récupérer les tentatives et le statut pour le niveau spécifique
                            const attemptsFromDB = (groupData.attemptsCodename && groupData.attemptsCodename[level]) || [];
                            setAttempts(attemptsFromDB);

                            const statusFromDB = (groupData.codenameStatus && groupData.codenameStatus[level]) || 'en_cours';
                            setCodenameStatus(statusFromDB);
                        }
                    }
                } catch (error) {
                    console.error('Erreur lors de la récupération des données du groupe :', error);
                }
            }
        };

        fetchGroupData();
    }, [group, level]);

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    // Fonction pour mélanger un tableau
    const shuffleArray = (array) => {
        return array.sort(() => Math.random() - 0.5);
    };

    const handleWordSelect = (word) => {
        if (codenameStatus !== 'en_cours') return;

        let newSelectedWords = [...selectedWords];
        if (newSelectedWords.includes(word)) {
            // Si le mot est déjà sélectionné, le désélectionner
            newSelectedWords = newSelectedWords.filter((w) => w !== word);
        } else {
            // Ajouter le mot si moins de 3 mots sont sélectionnés
            if (newSelectedWords.length < 3) {
                newSelectedWords.push(word);
            }
        }
        setSelectedWords(newSelectedWords);
    };

    const handleValidate = async () => {
        if (selectedWords.length !== 3) {
            setSnackbarMessage('Vous devez sélectionner exactement 3 mots.');
            setSnackbarSeverity('warning');
            setSnackbarOpen(true);
            return;
        }

        // Vérifier si les mots sélectionnés sont les bons
        const allCorrect = wordsToFind.every((word) => selectedWords.includes(word));

        const newAttempts = [...attempts, allCorrect];
        setAttempts(newAttempts);

        // Mettre à jour les tentatives dans la base de données pour le niveau spécifique
        try {
            const configRef = doc(db, 'config', 'game');
            const configSnap = await getDoc(configRef);

            if (configSnap.exists()) {
                const currentGameId = configSnap.data().current;
                const groupRef = doc(db, 'games', currentGameId, 'groups', group.id);
                await updateDoc(groupRef, {
                    [`attemptsCodename.${level}`]: newAttempts,
                });
            }
        } catch (error) {
            console.error('Erreur lors de la mise à jour des tentatives :', error);
        }

        if (allCorrect) {
            setCodenameStatus('resolu');

            // Mise à jour du statut et ajout des points
            try {
                const configRef = doc(db, 'config', 'game');
                const configSnap = await getDoc(configRef);

                if (configSnap.exists()) {
                    const currentGameId = configSnap.data().current;
                    const groupRef = doc(db, 'games', currentGameId, 'groups', group.id);
                    const teamRef = doc(db, 'games', currentGameId, 'teams', group.teamId);

                    // Mettre à jour le statut du groupe pour le niveau spécifique
                    await updateDoc(groupRef, {
                        [`codenameStatus.${level}`]: 'resolu',
                    });

                    // Ajouter des points au score de l'équipe
                    await updateDoc(teamRef, {
                        score: increment(codenamePointReward),
                    });
                }
            } catch (error) {
                console.error('Erreur lors de la mise à jour du statut et du score :', error);
            }

            // Afficher la Snackbar pour la réussite
            setSnackbarMessage('Félicitations ! Vous avez trouvé les bons mots.');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
        } else {
            // Vérifier si les tentatives sont épuisées
            const maxAttempts = level === '1' ? 3 : 2;
            if (newAttempts.length >= maxAttempts) {
                setCodenameStatus('echoue');

                // Mise à jour du statut dans la base de données pour le niveau spécifique
                try {
                    const configRef = doc(db, 'config', 'game');
                    const configSnap = await getDoc(configRef);

                    if (configSnap.exists()) {
                        const currentGameId = configSnap.data().current;
                        const groupRef = doc(db, 'games', currentGameId, 'groups', group.id);
                        await updateDoc(groupRef, {
                            [`codenameStatus.${level}`]: 'echoue',
                        });
                    }
                } catch (error) {
                    console.error('Erreur lors de la mise à jour du statut :', error);
                }

                // Afficher la Snackbar pour les tentatives épuisées
                setSnackbarMessage('Vous avez utilisé toutes vos tentatives.');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            } else {
                // Afficher la Snackbar pour une tentative incorrecte
                setSnackbarMessage('Mauvaise sélection, veuillez réessayer.');
                setSnackbarSeverity('warning');
                setSnackbarOpen(true);
            }
        }

        // Réinitialiser la sélection
        setSelectedWords([]);
    };

    if (loading) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: '40px',
                }}
            >
                <Typography variant="h6" sx={{ mt: 2 }}>
                    Chargement...
                </Typography>
            </Box>
        );
    }

    // Si le groupe ou l'équipe n'est pas authentifié, ne rien afficher ou un message
    if (!group || !team) {
        return null;
    }

    return (
        <Box
            sx={{
                mt: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 2,
            }}
        >
            <Typography variant="h4" gutterBottom>
                Codename
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
                Sélectionnez les 3 mots correspondant
            </Typography>

            {/* Affichage des tentatives restantes */}
            <Box sx={{ display: 'flex', mb: 2 }}>
                {Array.from({ length: level === '1' ? 3 : 2 }).map((_, index) => (
                    <Box
                        key={index}
                        sx={{
                            width: '20px',
                            height: '20px',
                            borderRadius: '50%',
                            backgroundColor:
                                attempts[index] === true
                                    ? 'green'
                                    : attempts[index] === false
                                        ? 'red'
                                        : 'grey',
                            margin: '0 5px',
                        }}
                    />
                ))}
            </Box>

            {/* Grille de mots */}
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: `repeat(${columns}, 1fr)`,
                    gap: 2,
                    mt: 2,
                    maxWidth: '800px',
                    width: '100%',
                }}
            >
                {gridWords.map((word, index) => (
                    <Card
                        key={index}
                        sx={{
                            border:
                                selectedWords.includes(word) && codenameStatus === 'en_cours'
                                    ? '2px solid blue'
                                    : '1px solid grey',
                            backgroundColor:
                                codenameStatus !== 'en_cours' && wordsToFind.includes(word)
                                    ? 'green'
                                    : 'white',
                            color:
                                codenameStatus !== 'en_cours' && wordsToFind.includes(word)
                                    ? 'white'
                                    : 'black',
                            cursor: codenameStatus === 'en_cours' ? 'pointer' : 'default',
                            minHeight: '80px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                        onClick={() => handleWordSelect(word)}
                    >
                        <CardContent>
                            <Typography variant="body1" align="center">
                                {word}
                            </Typography>
                        </CardContent>
                    </Card>
                ))}
            </Box>

            {/* Bouton de validation */}
            <Button
                variant="contained"
                color="primary"
                onClick={handleValidate}
                disabled={codenameStatus !== 'en_cours'}
                sx={{ mt: 2 }}
            >
                Valider
            </Button>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity}
                    sx={{ width: '100%' }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default Codename;
