// src/MainApp.js

import React, { useContext, useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { TeamContext } from './contexts/TeamContext';
import { AdminContext } from './contexts/AdminContext'; // Import du AdminContext
import TeamCodeModal from './components/TeamCodeModal/TeamCodeModal';
import AdminCodeModal from './components/AdminCodeModal/AdminCodeModal'; // Import du AdminPasswordModal
import { doc, getDoc, onSnapshot } from 'firebase/firestore';
import { db } from './firebase';

// Import des composants
import Sudoku from './components/Sudoku/Sudoku';
import Dingbats from './components/Dingbats/Dingbats';
import Admin from './components/Admin/Admin';
import Score from './components/Score/Score';
import Bomb from "./components/Bomb/Bomb";
import Codename from "./components/Codename/Codename";
import Enigme from "./components/Enigme/Enigme";
import {Box} from "@mui/material";

function Main() {
    const { group, setGroup, setTeam } = useContext(TeamContext);
    const { isAdminAuthenticated, setIsAdminAuthenticated } = useContext(AdminContext);
    const location = useLocation();
    const [isHacked, setIsHacked] = useState(false);

    const isAdminRoute = location.pathname === '/admin';

    // Charger les informations de l'équipe lorsque le groupe est authentifié
    useEffect(() => {
        const fetchTeamData = async () => {
            if (group && group.teamId) {
                try {
                    const configRef = doc(db, 'config', 'game');
                    const configSnap = await getDoc(configRef);

                    if (configSnap.exists()) {
                        const currentGameId = configSnap.data().current;
                        const teamRef = doc(db, 'games', currentGameId, 'teams', group.teamId);
                        const teamSnap = await getDoc(teamRef);

                        if (teamSnap.exists()) {
                            const teamData = { id: teamSnap.id, ...teamSnap.data() };
                            setTeam(teamData);
                        } else {
                            console.error('Équipe introuvable pour le groupe authentifié.');
                        }
                    }
                } catch (error) {
                    console.error('Erreur lors de la récupération des informations de l\'équipe :', error);
                }
            }
        };

        fetchTeamData();
    }, [group, setTeam]);

    // Souscrire aux changements du champ 'hacking' dans 'config/game'
    useEffect(() => {
        const configRef = doc(db, 'config', 'game');
        const unsubscribe = onSnapshot(configRef, (configSnap) => {
            if (configSnap.exists()) {
                const hacking = configSnap.data().hacking;
                setIsHacked(hacking || false);
            }
        }, (error) => {
            console.error('Erreur lors de la souscription aux changements du champ hacking :', error);
        });

        // Nettoyer la souscription lors du démontage du composant
        return () => {
            unsubscribe();
        };
    }, []);

    return (
        <div>
            {/* Afficher le logo en fonction de isHacked */}
            <header>
                <Box
                    sx={{
                        width: '100%',
                        maxWidth: 400,
                        mx: 'auto', // Centre le logo horizontalement
                        mt: 2, // Marge supérieure
                    }}
                >
                    <img
                        src={isHacked ? '/assets/logo-hacked.png' : '/assets/logo.png'}
                        alt="Logo"
                        style={{
                            width: '100%',
                            height: 'auto',
                            display: 'block',
                        }}
                    />
                </Box>
            </header>
            {/* Afficher le TeamCodeModal si le groupe n'est pas authentifié et qu'on n'est pas sur une route restreinte */}
            {!isAdminRoute && !group && (
                <TeamCodeModal
                    open={true}
                    onGroupAuthenticated={(groupData) => {
                        setGroup(groupData);
                        // L'équipe sera chargée automatiquement par le useEffect
                    }}
                />
            )}
            {/* Afficher le AdminPasswordModal si on est sur /admin et que l'admin n'est pas authentifié */}
            {isAdminRoute && !isAdminAuthenticated && (
                <AdminCodeModal
                    open={true}
                    onClose={() => {}}
                />
            )}
            <Routes>
                <Route path="/sudoku1" element={<Sudoku level={'1'} />} />
                <Route path="/sudoku2" element={<Sudoku level={'2'} />} />
                <Route path="/dingbats1" element={<Dingbats level={'1'} />} />
                <Route path="/dingbats2" element={<Dingbats level={'2'} />} />
                <Route path="/bomb1" element={<Bomb level={'1'} />} />
                <Route path="/bomb2" element={<Bomb level={'2'} />} />
                <Route path="/codename1" element={<Codename level={'1'} />} />
                <Route path="/codename2" element={<Codename level={'2'} />} />
                <Route path="/enigme1" element={<Enigme level={'1'} />} />
                <Route path="/enigme2" element={<Enigme level={'2'} />} />
                <Route
                    path="/admin"
                    element={
                        isAdminAuthenticated ? (
                            <Admin />
                        ) : null
                    }
                />
                <Route path="/score" element={<Score />} />
            </Routes>
        </div>
    );
}

export default Main;
