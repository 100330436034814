// src/components/Admin/Admin.js

import React, { useState } from 'react';
import {Button, Snackbar, Alert, Box} from '@mui/material';
import { db } from '../../firebase'; // Adjust the path according to your project structure
import {collection, doc, setDoc, updateDoc} from 'firebase/firestore';
import Score from "../Score/Score";
import { groupCodes } from "../../config/config";

function Admin() {
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // 'success' or 'error'
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [isHacked, setIsHacked] = useState(false);

    const handleHack = async () => {
        try {
            const configRef = doc(db, 'config', 'game');
            await updateDoc(configRef, {
                hacking: !isHacked,
            });
            setIsHacked(!isHacked);

            // Show success message
            setSnackbarMessage('Piratage en cours.');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
        } catch (error) {
            console.error('Error starting a new game:', error);

            // Show error message
            setSnackbarMessage('Une erreur est survenue lors du piratage.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    }

    const handleStartNewGame = async () => {
        try {
            // Create a new game
            const gamesRef = collection(db, 'games');
            const newGameRef = doc(gamesRef); // Automatically generated ID
            const gameId = newGameRef.id;

            // Define teams
            const teams = ['green', 'blue', 'yellow', 'red'];
            // Define groups for each team
            const groupSuffixes = ['alpha', 'beta'];

            // Initialize the game with creation date
            await setDoc(newGameRef, {
                id: gameId,
                creationDate: new Date().toISOString(),
            });

            // Add teams as subcollection
            for (const team of teams) {
                const teamRef = doc(newGameRef, 'teams', team);
                await setDoc(teamRef, {
                    score: 0,
                });
            }

            // Add groups as subcollection
            for (const team of teams) {
                for (const suffix of groupSuffixes) {
                    const groupId = `${team}-${suffix}`;
                    const groupRef = doc(newGameRef, 'groups', groupId);
                    await setDoc(groupRef, {
                        teamId: team,
                        code: groupCodes[groupId],
                        attemptsSudoku: {},
                        sudokuStatus: {},
                        dingbatsAttempts: {},
                        attemptsBomb: {},
                        codenameStatus: {},
                        enigmeStatus: {},
                        // Any other group-specific data
                    });
                }
            }

            // Update the 'game' document in the 'config' collection
            const configRef = doc(db, 'config', 'game');
            await setDoc(configRef, {
                current: gameId,
                hacking: false
            });

            // Show success message
            setSnackbarMessage('Nouvelle partie démarrée avec succès.');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
        } catch (error) {
            console.error('Error starting a new game:', error);

            // Show error message
            setSnackbarMessage('Une erreur est survenue lors du démarrage de la nouvelle partie.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };

    // Handle closing the Snackbar
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    return (
        <div>
            <Box sx={{ display: 'flex', gap: '10px', justifyContent: 'center', alignItems: 'center' }}>                <Button variant="contained" color="primary" onClick={handleStartNewGame}>
                    Démarrer une nouvelle partie
                </Button>

                <Button variant="contained" color="warning" onClick={handleHack}>
                    Piratage
                </Button>
            </Box>

            <Score />

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </div>
    );
}

export default Admin;
