// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDmcPSqWGq-LNMTACM2gJgtXEOJBPeM4Kw",
    authDomain: "cs-1-fc0f8.firebaseapp.com",
    projectId: "cs-1-fc0f8",
    storageBucket: "cs-1-fc0f8.appspot.com",
    messagingSenderId: "104801104438",
    appId: "1:104801104438:web:040a08223d9961f1a11e97",
    measurementId: "G-E39QC49T34"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };