// src/components/Score/Score.js

import React, { useEffect, useState } from 'react';
import { db } from '../../firebase'; // Adjust the path according to your project structure
import { doc, collection, onSnapshot, query, where, getDocs } from 'firebase/firestore';
import { Grid, Typography, CircularProgress, Box } from '@mui/material';
import { useLocation } from 'react-router-dom';
import TeamCard from "./TeamCard";

function Score() {
    const [teamsData, setTeamsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const location = useLocation();

    // Déterminer si le composant est rendu sous '/admin' ou '/score'
    const isAdmin = location.pathname === '/admin';

    useEffect(() => {
        let unsubscribeTeams;
        let unsubscribeConfig;

        const fetchScores = async () => {
            try {
                // Écouter les changements du document 'config/game'
                const configRef = doc(db, 'config', 'game');
                unsubscribeConfig = onSnapshot(configRef, async (configSnap) => {
                    if (configSnap.exists()) {
                        const currentGameId = configSnap.data().current;

                        if (currentGameId) {
                            // Récupérer les équipes du jeu actuel
                            const teamsCollectionRef = collection(db, 'games', currentGameId, 'teams');

                            // Écouter les mises à jour en temps réel des équipes
                            unsubscribeTeams = onSnapshot(teamsCollectionRef, async (snapshot) => {
                                const teamsList = snapshot.docs.map((doc) => ({
                                    id: doc.id,
                                    ...doc.data(),
                                }));

                                // Pour chaque équipe, récupérer les groupes associés
                                for (let team of teamsList) {
                                    const groupsCollectionRef = collection(db, 'games', currentGameId, 'groups');
                                    const q = query(groupsCollectionRef, where('teamId', '==', team.id));
                                    const groupsSnapshot = await getDocs(q);
                                    team.groups = groupsSnapshot.docs.map((groupDoc) => ({
                                        id: groupDoc.id,
                                        ...groupDoc.data(),
                                    }));
                                }

                                setTeamsData(teamsList);
                                setLoading(false);
                            });
                        } else {
                            console.error('Aucun jeu actuel trouvé dans la configuration.');
                            setTeamsData([]);
                            setLoading(true); // Afficher le spinner
                        }
                    } else {
                        console.error('Aucun document de configuration trouvé.');
                        setTeamsData([]);
                        setLoading(true); // Afficher le spinner
                    }
                });
            } catch (error) {
                console.error('Erreur lors de la récupération des scores :', error);
                setLoading(false);
            }
        };

        fetchScores();

        // Fonction de nettoyage pour se désabonner lors du démontage du composant
        return () => {
            if (unsubscribeTeams) {
                unsubscribeTeams();
            }
            if (unsubscribeConfig) {
                unsubscribeConfig();
            }
        };
    }, []);

    if (loading) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: '40px',
                }}
            >
                <CircularProgress />
                <Typography variant="h6" sx={{ mt: 2 }}>
                    En attente du lancement de la partie...
                </Typography>
            </Box>
        );
    }

    return (
        <div style={{ padding: '20px' }}>
            <Grid container spacing={4} justifyContent="center">
                {teamsData.map((team) => (
                    <TeamCard key={team.id} team={team} isAdmin={isAdmin} />
                ))}
            </Grid>
        </div>
    );
}

export default Score;
