// src/contexts/TeamContext.js

import React, { createContext, useState } from 'react';

export const TeamContext = createContext();

export function TeamProvider({ children }) {
    const [group, setGroup] = useState(null); // Informations du groupe
    const [team, setTeam] = useState(null);   // Informations de l'équipe correspondante

    return (
        <TeamContext.Provider value={{ group, setGroup, team, setTeam }}>
            {children}
        </TeamContext.Provider>
    );
}
