import React from 'react';
import {
    Grid,
    TextField,
    Container,
    Select,
    MenuItem,
    FormControl,
    Box,
} from '@mui/material';

function SudokuGrid({ sudoku, setSudoku, disabled, level }) {
    const handleInputChange = (row, col, value) => {
        const newGrid = [...sudoku];
        newGrid[row][col] = value;
        setSudoku(newGrid);
    };

    // Liste des options pour le niveau 2 (images)
    const imageOptions = [
        { value: '1', label: '', src: '/assets/sudoku/1.png' },
        { value: '2', label: '', src: '/assets/sudoku/2.png' },
        { value: '3', label: '', src: '/assets/sudoku/3.png' },
        { value: '4', label: '', src: '/assets/sudoku/4.png' },
        { value: '5', label: '', src: '/assets/sudoku/5.png' },
        { value: '6', label: '', src: '/assets/sudoku/6.png' },
        { value: '7', label: '', src: '/assets/sudoku/7.png' },
        { value: '8', label: '', src: '/assets/sudoku/8.png' },
        { value: '9', label: '', src: '/assets/sudoku/9.png' },
    ];

    return (
        <Container>
            <Grid container spacing={1} direction="column">
                {sudoku.map((row, rowIndex) => (
                    <Grid
                        container
                        item
                        spacing={1}
                        key={rowIndex}
                        justifyContent="center"
                    >
                        {row.map((cell, colIndex) => (
                            <Grid item key={`${rowIndex}-${colIndex}`}>
                                {level === '1' && (
                                    <TextField
                                        value={cell}
                                        onChange={(e) =>
                                            handleInputChange(rowIndex, colIndex, e.target.value)
                                        }
                                        variant="outlined"
                                        margin="normal"
                                        style={{ width: '50px', textAlign: 'center' }}
                                        disabled={disabled}
                                    />
                                )}
                                {level === '2' && (
                                    <FormControl
                                        variant="outlined"
                                        style={{ minWidth: 80, margin: '8px' }}
                                        disabled={disabled}
                                    >
                                        <Select
                                            value={cell}
                                            onChange={(e) =>
                                                handleInputChange(rowIndex, colIndex, e.target.value)
                                            }
                                            label="Image"
                                        >
                                            {imageOptions.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.src ? (
                                                        <Box
                                                            component="img"
                                                            src={option.src}
                                                            alt={option.label}
                                                            sx={{
                                                                width: '30px',
                                                                height: '30px',
                                                                marginRight: '8px',
                                                            }}
                                                        />
                                                    ) : null}
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )}
                            </Grid>
                        ))}
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
}

export default SudokuGrid;
